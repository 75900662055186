import axiosInstance from '@/plugins/proxyHandler'

export default {
    async postErrorLog(payload) {
        try {
            return await axiosInstance.post(`/api/v1/common/error`, {
                ...payload
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
};
