import loginAPI from "@/api/loginAPI";
import router from "@/router";
import axios from "axios";
import errorAPI from "@/api/errorAPI";

// state
const state = {
    id: process.env.VUE_APP_USER_ID,
    member: process.env.VUE_APP_USER_TOKEN || null,
    interestTherapeuticArea: '',
    main: '',
}

// mutations
const mutations = {
    setMember(state, {member}) {
        state.member = member
        return member
    },
    setId(state, {id}) {
        return state.id = id
    },
    setInterestTherapeuticArea(state, {interestTherapeuticArea}) {
        state.interestTherapeuticArea = interestTherapeuticArea
    },
    setInterestTherapeuticAreaMainVisual(state, {main}) {
        state.main = main
    },
}

// actions
const actions = {
    async fetchMember({state, commit}) {
        try {
            const {data} = await loginAPI.getMember(state.id);
            commit('setMember', {member: data})
            return data
        } catch (e) {
            commit('setMember', {member: null})
            console.log(e)
            throw e
        }
    },
    setId({commit}, {id}) {
        commit('setId', {id: id})
        return id
    },
    logout({commit}) {
        commit('setId', {id: null})
        commit('setMember', {member: null})
        window.sessionStorage.clear()
        return router.push('/main')
    },
    async checkLogin() {
        try {
            const {data} = await loginAPI.checkLogin()
            return data
        } catch (e) {
            return false
        }
    },
    async initMyInterestTherapeuticArea({commit}) {
        try {
            const {data: {value:{id}}} = await loginAPI.myInterestTherapeuticArea()
            commit('setInterestTherapeuticArea', {interestTherapeuticArea: id})
            return id
        } catch (e) {
            commit('setInterestTherapeuticArea', {interestTherapeuticArea: undefined})
            return false
        }
    },
    async myInterestTherapeuticAreaMainVisual({commit, state}) {
        try {
            const {data: {value:{ename}}} = await loginAPI.myInterestTherapeuticArea()
            axios.get(`https://cdn.bmson.co.kr/static/main/${ename}${process.env.VUE_APP_HTML_SUFFIX}.html`)
                .then(({data}) => commit('setInterestTherapeuticAreaMainVisual', {main: data}))
                .catch(error => {
                    errorAPI.postErrorLog({
                        message : `${ename}${process.env.VUE_APP_HTML_SUFFIX}.html ${error.response.data} ${error.response.headers} ${error.response.status}`,
                        code: 'main-visual',
                        token : state.member,
                    }).then()
                    axios.get(`https://cdn.bmson.co.kr/static/main/no_select_ta${process.env.VUE_APP_HTML_SUFFIX}.html`)
                        .then(({data}) => commit('setInterestTherapeuticAreaMainVisual', {main: data}))
                        .catch(error => {
                            errorAPI.postErrorLog({
                                message : `no_select_ta${process.env.VUE_APP_HTML_SUFFIX}.html ${error.response.data} ${error.response.headers} ${error.response.status}`,
                                code: 'main-visual',
                                token : state.member,
                            }).then()
                            this.html = ''
                        })
                })
            return ename
        } catch (e) {
            axios.get(`https://cdn.bmson.co.kr/static/main/no_select_ta${process.env.VUE_APP_HTML_SUFFIX}.html`)
                .then(({data}) => commit('setInterestTherapeuticAreaMainVisual', {main: data}))
                .catch(error => {
                    errorAPI.postErrorLog({
                        message : `no_select_ta${process.env.VUE_APP_HTML_SUFFIX}.html ${error.response.data} ${error.response.headers} ${error.response.status}`,
                        code: 'main-visual',
                        token : state.member,
                    }).then()
                    this.html = ''
                })
            return null
        }
    },
}

// getters
const getters = {
    isLogin(state) {
        return !!state.member
    },
    getMember(state) {
        return state.member
    },
    getId(state) {
        return state.id
    },
    getInterestTherapeuticArea(state) {
        return state.interestTherapeuticArea
    },
    getInterestTherapeuticAreaMainVisual(state) {
        return state.main
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
