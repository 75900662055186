import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store'
import productRouter  from "@/router/modules/product";
import mypageRouter from "@/router/modules/mypage";
import VueGtag from "vue-gtag";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: () => import('@/views'),
        meta: {
            displayName: 'BMSON Official Main', //이렇게 추가 부탁드립니다.
            auth: true
        },
    },
    {
        path: '/main',
        name: 'noLogin',
        component: () => import('@/views/noLogin'),
        meta: {
            displayName: 'BMSON Introduce & Login',
        }
    },
    {
        path: '/request-service',
        name: 'RequestService',
        component: () => import('@/views/RequestService'),
        meta: {
            displayName: 'BMSON Employee Visit Request',
            auth: true
        },
    },
    {
        path: '/request-report',
        name: 'RequestReport',
        component: () => import('@/views/RequestReport'),
        meta: {
            displayName: 'BMSON MIR & AE Application',
            auth: true
        },
    },
    {
        path: '/news/web-magazine',
        name: 'WebMagazine',
        component: () => import('@/views/News/WebMagazine'),
        meta: {
            displayName: 'BMSON Webmagazine',
            auth: true
        },
    },
    {
        path: '/news/news-and-notice',
        name: 'NewsList',
        component: () => import('@/views/News/NewsAndNotice'),
        meta: {
            displayName: 'BMSON News & Notice',
            auth: true
        },
    },
    {
        path: '/news/news-and-notice/:newsNo',
        name: 'NewsView',
        component: () => import('@/views/News/NewsAndNotice/View'),
        meta: {
            displayName: 'BMSON News Contents',
            auth: true
        },
    },
    {
        path: '/news/survey-event',
        name: 'SurveyEventList',
        component: () => import('@/views/News/SurveyEvent'),
        meta: {
            displayName: 'BMSON Survey Event',
            auth: true
        },
    },
    {
        path: '/news/survey-event/:surveyEventNo',
        name: 'SurveyEventView',
        component: () => import('@/views/News/SurveyEvent/View'),
        meta: {
            displayName: 'BMSON Survey Event Contents',
            auth: true
        },
    },
    {
        path: '/news/social-contribute',
        name: 'SocialContribute',
        component: () => import('@/views/News/SocialContribute'),
        meta: {
            displayName: 'BMSON Social Contribution',
            auth: true
        },
    },
    {
        path: '/news/social-contribute/:socialContributeNo',
        name: 'SocialContributeView',
        component: () => import('@/views/News/SocialContribute/View'),
        meta: {
            displayName: 'BMSON Social Contribution Contents',
            auth: true
        },
    },

    {
        path: '/webinar-symposium/webinar-live',
        name: 'WebinarLiveList',
        component: () => import('@/views/WebinarAndSymposium/WebinarLive'),
        meta: {
            displayName: 'BMSON Webinar Live',
            auth: true
        },
    },
    {
        path: '/webinar-symposium/webinar-live/:webinarLiveNo',
        name: 'WebinarLiveView',
        component: () => import('@/views/WebinarAndSymposium/WebinarLive/View'),
        meta: {
            displayName: 'BMSON Webinar Live Contents',
            auth: true
        },
    },
    {
        path: '/webinar-symposium/webinar-vod',
        name: 'WebinarVODList',
        component: () => import('@/views/WebinarAndSymposium/WebinarVOD'),
        meta: {
            displayName: 'BMSON Webinar VOD',
            auth: true
        },
    },
    {
        path: '/webinar-symposium/webinar-vod/:webinarVodNo',
        name: 'WebinarVODView',
        component: () => import('@/views/WebinarAndSymposium/WebinarVOD/View'),
        meta: {
            displayName: 'BMSON Webinar VOD Contents',
            auth: true
        },
    },
    {
        path: '/webinar-symposium/symposium-vod',
        name: 'SymposiumVODList',
        component: () => import('@/views/WebinarAndSymposium/SymposiumVOD'),
        meta: {
            displayName: 'BMSON Symposium VOD',
            auth: true
        },
    },
    {
        path: '/webinar-symposium/symposium-vod/:symposiumVodNo',
        name: 'SymposiumVODView',
        component: () => import('@/views/WebinarAndSymposium/SymposiumVOD/View'),
        meta: {
            displayName: 'BMSON Symposium VOD Contents',
            auth: true
        },
    },
    {
        path: '/webinar-symposium/user-guide',
        name: 'UserGuide',
        component: () => import('@/views/WebinarAndSymposium/UserGuide'),
        meta: {
            displayName: 'BMSON Webinar & Live User Guide',
            auth: true
        },
    },
    {
        path: '/sitemap',
        name: 'Sitemap',
        component: () => import('@/components/common/Sitemap'),
        meta: {
            displayName: 'BMSON Sitemap',
            auth: true
        },
    },
    {
        path: '/contact-us',
        name: 'ContactUs',
        component: () => import('@/components/common/ContactUs'),
        meta: {
            displayName: 'BMSON Contact Us',
            auth: true
        },
    },
    {
        path: '/search/result',
        name: 'searchResultPage',

        props: true,
        component: () => import('@/views/Search/searchResultPage'),
        meta: {
            displayName: 'BMSON Search',
            auth: true,
        },
    },
    {
        path: '/shared',
        name: 'sharedPage',
        props: true,
        component: () => import('../views/Shared'),
        meta: {
            displayName: 'BMSON Shared Page',
            auth: false,
            layout: "SharedLayout"
        },
    },
    productRouter,
    mypageRouter,
].map(route => {
    return {
        ...route,
        meta: {
            layout: "MainLayout",
            ...route.meta
        }
    }
});



const router = new VueRouter({
    mode: 'history',
    routes,
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else if (to.hash) {
            return {
                selector: to.hash,
                offset: { x: 0, y: 0 }
            }
        } else {
            return {x: 0, y: 0}
        }
    },
});

Vue.use(VueGtag, {
    config: { id: "G-5MY9C5J111" },
    appName: 'BMSON - Bristol Myers Squibb KOREA',
    pageTrackerScreenviewEnabled: true,
    pageTrackerTemplate(to) {
        return {
            page_title: to.meta['displayName'],
            page_path: to.path
        }
    }
}, router);

router.beforeEach(async (to, from, next) => {
    let {auth} = to.meta
    let member = store.getters.getMember
    let query = Object.assign({}, to.query);
    let {id} = to.query
    let ta = true
    if (id) {
        await store.dispatch('setId', {id: id})
        delete query.id;
    }
    if (auth && member === null) {
        store.dispatch('fetchMember').then(() => {
            if (id || query.needta) {
                next({path: to.path, ...query && {query: query}})
            } else {
                next()
            }
        }).catch(() => {
            console.log('인증이 필요합니다.');
            delete query.needta;
            query = {
                ...query,
                redirect: to.path
            }
            next({path: "/main", ...query && {query: query}})
        })
    } else {
        if (to.path === '/' && !(store.getters.getInterestTherapeuticArea || query['needta'])) {
            ta = await store.dispatch('initMyInterestTherapeuticArea')
            if (!ta) {
                query['needta'] = true
            }
        }
        if (id || (!ta && !to.query['needta'])) {
            next({path: to.path, ...query && {query: query}})
        } else {
            next()
        }
    }
})

export default router;
