import webinarAPI from "@/api/webinarAPI";

// state
const state = {
    webinarList: [],
    webinarVODList: [],
    webinarCalendarList: {},
    webinar: {},
    webinarVOD: {},
    isLast : true,
    page: 0,
    webinarVODLastUpdatedAt: null,
}

// mutations
const mutations = {
    setWebinarList(state, {webinarList}) {
        state.webinarList = webinarList
        return state.webinarList
    },
    setWebinarCalendarList(state, {webinarCalendarList}) {
        state.webinarCalendarList = webinarCalendarList
        return state.webinarCalendarList
    },
    setWebinarVODList(state, {webinarVODList}) {
        state.webinarVODList = webinarVODList
        return state.webinarVODList
    },
    setWebinar(state, {webinar}) {
        state.webinar = webinar
        return state.webinar
    },
    setWebinarVOD(state, {webinarVOD}) {
        state.webinarVOD = webinarVOD
        return state.webinarVOD
    },
    fetchWebinarVODList(state, {webinarVODList}) {
        state.webinarVODList = [...state.webinarVODList, ...webinarVODList]
        return state.webinarVOD
    },
    setWebinarVODIsLast(state, {isLast}){
        state.isLast = isLast
        return state.isLast
    },
    setWebinarVODPage(state, {page}){
        state.page = page
        return state.page
    },
    fetchWebinarVODLastUpdatedAt(state, {lastUpdatedAt}) {
        state.lastUpdatedAt = lastUpdatedAt
        return state.lastUpdatedAt
    },
}

// actions
const actions = {
    async initWebinarList({commit}, {query}) {
        try {
            const {data: {value}} = await webinarAPI.getWebinarList(query);
            commit('setWebinarList', {webinarList: value})
            return value
        } catch (e) {
            commit('setWebinarList', {webinarList: null})
            throw e
        }
    },
    async initWebinarVODList({commit}, {query}) {
        try {
            const {data: {value: {content, last, number}}} = await webinarAPI.getWebinarVODList(query);
            const {data: {value: lastUpdatedAt}} = await webinarAPI.getWebinarVODLastUpdatedAt(query);
            commit('setWebinarVODList', {webinarVODList: content})
            commit('setWebinarVODIsLast', {isLast: last})
            commit('setWebinarVODPage', {page: number})
            commit('fetchWebinarVODLastUpdatedAt', {lastUpdatedAt: lastUpdatedAt})
        } catch (e) {
            commit('setWebinarVODList', {webinarVODList: null})
            commit('setWebinarVODIsLast', {isLast: true})
            commit('setWebinarVODPage', {page: 0})
            commit('fetchWebinarVODLastUpdatedAt', {lastUpdatedAt: null})
        }
    },
    async fetchWebinarVODList({commit}, {query}) {
        try {
            const {data: {value: {content, last, number}}} = await webinarAPI.getWebinarVODList(query);
            const {data: {value: lastUpdatedAt}} = await webinarAPI.getWebinarVODLastUpdatedAt(query);
            commit('fetchWebinarVODList', {webinarVODList: content})
            commit('setWebinarVODIsLast', {isLast: last})
            commit('setWebinarVODPage', {page: number})
            commit('fetchWebinarVODLastUpdatedAt', {lastUpdatedAt: lastUpdatedAt})
        } catch (e) {
            commit('setWebinarVODList', {webinarVODList: null})
            commit('setWebinarVODIsLast', {isLast: true})
            commit('setWebinarVODPage', {page: 0})
            commit('fetchWebinarVODLastUpdatedAt', {lastUpdatedAt: null})
        }
    },
    async initWebinarCalendarList({commit}, {query}) {
        try {
            const {data: {value}} = await webinarAPI.getWebinarCalendarList(query);
            commit('setWebinarCalendarList', {webinarCalendarList: value})
            return value
        } catch (e) {
            commit('setWebinarCalendarList', {webinarCalendarList: null})
            throw e
        }
    },
    async fetchWebinar({commit}, {id}) {
        try {
            const {data: {value}} = await webinarAPI.getWebinar(id);
            commit('setWebinar', {webinar: value})
            return value
        } catch (e) {
            commit('setWebinar', {webinar: null})
            throw e
        }
    },
    async fetchWebinarNotPoint(_, {id}) {
        const {data: {value}} = await webinarAPI.getWebinarNotPoint(id);
        return value
    },
    async fetchWebinarVOD({commit}, {id}) {
        try {
            const {data: {value}} = await webinarAPI.getWebinarVOD(id);
            commit('setWebinarVOD', {webinarVOD: value})
            return value
        } catch (e) {
            commit('setWebinarVOD', {webinarVOD: null})
            throw e
        }
    },
}

// getters
const getters = {
    getWebinarList(state) {
        return state.webinarList
    },
    getWebinar(state) {
        return state.webinar
    },
    getWebinarVODList(state) {
        return state.webinarVODList
    },
    getWebinarVODIsLast(state) {
        return state.isLast
    },
    getWebinarVODPage(state) {
        return state.page
    },
    getWebinarVODLastUpdatedAt(state) {
        return state.webinarVODLastUpdatedAt
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}
