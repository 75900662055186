// state
import surveyAPI from "@/api/surveyAPI";



const state = {
    surveyList: [],
    survey: {},
    isLast: false,
    page: 0
}

// mutations
const mutations = {
    setSurveyList(state, {surveyList}) {
        if (state.page === 0) {
            state.surveyList = []
        }
        state.surveyList = [...state.surveyList, ...surveyList]
        return state.surveyList
    },
    setSurvey(state, {survey}) {
        state.survey = survey
        return state.survey
    },
    setIsLast(state, {isLast}) {
        state.isLast = isLast
        return state.isLast
    },
    setPage(state, {page}) {
        state.page = page
        return state.page
    }
}

// actions
const actions = {
    async fetchSurveyList({commit, state}, {query}) {
        try {
            const {data: {value: {content, last, number}}} = await surveyAPI.fetchSurveyList(query);
            commit('setIsLast', {isLast: last})
            commit('setPage', {page: number})
            commit('setSurveyList', {surveyList: content})
            return state.surveyList
        } catch (e) {
            commit('setIsLast', {isLast: false})
            commit('setPage', {page: 0})
            commit('setSurveyList', {surveyList: null})
            return null
        }
    },
    async fetchSurvey({commit, state}, {id}) {
        try {
            const {data: {value}} = await surveyAPI.fetchSurvey(id);
            commit('setSurvey', {survey: value})
            return state.survey
        } catch (e) {
            commit('setSurvey', {survey: null})
            return null
        }
    },

}

// getters
const getters = {
    getSurveyList(state) {
        return state.surveyList
    },
    getSurveyLastUpdate(state) {
        function to_date2(date_str){
            let yyyyMMdd = String(date_str);
            let sYear = yyyyMMdd.substring(0,4);
            let sMonth = yyyyMMdd.substring(5,7);
            let sDate = yyyyMMdd.substring(8,10);

            //alert("sYear :"+sYear +"   sMonth :"+sMonth + "   sDate :"+sDate);
            return new Date(Number(sYear), Number(sMonth)-1, Number(sDate));
        }
        let maxUpdateDate = state.surveyList.map(value => to_date2(value.updateDate)).sort().reverse()[0]
        return state.surveyList.length > 0 ? maxUpdateDate : ''
    },
    getSurveyListPage(state) {
        return state.page
    },
    getSurveyListIsLast(state) {
        return state.isLast
    },
    getSurvey(state) {
        return state.survey
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}
