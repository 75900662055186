import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from "@/router";
import store from '@/store'
import axios from "axios";
import moment from "moment";
import VueMomentJs from "vue-momentjs"
import VueHtmlToPaper from 'vue-html-to-paper';
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)
Vue.use(VueMomentJs,moment)
Vue.use(VueHtmlToPaper)

Vue.config.productionTip = false

Vue.prototype.$axios = axios

if(!String.prototype.padStart) {
  String.prototype.padStart = function padStart(targetLength, padString) {
    if(this.length >= targetLength) {
      return String(this);
    } else {
      if(padString == null || padString == " ") {
        padString = " ";
      } else if(padString.length > 1) {
        padString = padString.substring(0,1);
      }
      targetLength = targetLength - this.length;
      var prefix = "";
      for(var i = 0; i < targetLength; i++) {
        prefix += padString;
      }
      return prefix + String(this);
    }
  };
}

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
