import boardAPI from "@/api/boardAPI";


// state
const state = {
    boardList: [],
    isLast: true,
    page: 0,
    board: {
        title: ''
    },
    lastUpdatedAt: null,
}

// mutations
const mutations = {
    setBoard(state, {board}) {
        state.board = board
        return state.board
    },
    setBoardList(state, {boardList}) {
        state.boardList = boardList
        return state.boardList
    },
    fetchBoardList(state, {boardList}) {
        state.boardList = [...state.boardList, ...boardList]
        return state.boardList
    },
    fetchIsLast(state, {isLast}) {
        state.isLast = isLast
        return state.isLast
    },
    fetchPage(state, {page}) {
        state.page = page
        return state.page
    },
    fetchLastUpdatedAt(state, {lastUpdatedAt}) {
        state.lastUpdatedAt = lastUpdatedAt
        return state.lastUpdatedAt
    },
}

// actions
const actions = {
    async initBoard({commit}, {type, id}) {
        try {
            const {data: {value}} = await boardAPI.getBoardByType(type, id);
            commit('setBoard', {board: value})
            return value
        } catch (e) {
            commit('setBoard', {board: null})
            throw e
        }
    },
    async fetchWebMagazine({commit}, {type, year, month}) {
        try {
            const {data: {value}} = await boardAPI.getWebMagazine(type, year, month);
            commit('setBoard', {board: value})
            return value
        } catch (e) {
            commit('setBoard', {board: null})
            return null
        }
    },
    initBoardList: async function ({commit}, {query, type, mapFunction}) {
        if (typeof mapFunction === "undefined") {
            mapFunction = (list) => list;
        }
        try {
            commit('setBoardList', {boardList: []})
            commit('fetchIsLast', {isLast: true})
            commit('fetchPage', {page: 0})
            commit('fetchLastUpdatedAt', {lastUpdatedAt: null})
            const {data: {value: {content, last, number}}} = await boardAPI.getBoardListByType(query, type);
            const {data: {value: lastUpdatedAt}} = await boardAPI.getBoardsLastUpdatedAt(query, type);
            commit('setBoardList', {boardList: content.map(mapFunction)})
            commit('fetchIsLast', {isLast: last})
            commit('fetchPage', {page: number})
            commit('fetchLastUpdatedAt', {lastUpdatedAt: lastUpdatedAt})
            return {
                boardList: state.boardList,
                isLast: state.isLast,
                lastUpdatedAt: state.lastUpdatedAt,
            }
        } catch (e) {
            commit('setBoardList', {boardList: []})
            commit('fetchLastUpdatedAt', {lastUpdatedAt: null})
            throw e
        }
    },
    async fetchBoardList({commit}, {query, type, mapFunction}) {
        if(typeof mapFunction === "undefined") {
            mapFunction = (list) => list;
        }
        try {
            const {data: {value: {content, last, number}}} = await boardAPI.getBoardListByType(query, type);
            const {data: {value: lastUpdatedAt}} = await boardAPI.getBoardsLastUpdatedAt(query, type);
            commit('fetchBoardList', {boardList: content.map(mapFunction)})
            commit('fetchIsLast', {isLast: last})
            commit('fetchPage', {page: number})
            commit('fetchLastUpdatedAt', {lastUpdatedAt: lastUpdatedAt})
            return {
                boardList: state.boardList,
                isLast: state.isLast,
                lastUpdatedAt: state.lastUpdatedAt,
            }
        } catch (e) {
            commit('setBoardList', {boardList: []})
            commit('fetchLastUpdatedAt', {lastUpdatedAt: null})
            throw e
        }
    },
}

// getters
const getters = {
    getBoardList(state) {
        return state.boardList
    },
    isLast(state) {
        return state.isLast
    },
    getPage(state) {
        return state.page
    },
    getBoard(state) {
        return state.board
    },
    getLastUpdatedAt(state) {
        return state.lastUpdatedAt
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
