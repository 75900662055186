<template>
  <div class="site">
    <div class="site-contain">
      <v-slide-x-transition>
        <mobile-header
            v-if="isMobileMenuVisible"
            @show-mobile-menu-open="showMobileMenu"
            :isLogin="isLogin"
            @show-external-modal="showExternalModal"
            @logout="logOut"
            @getSearchData="getSearchModalData"
        />
      </v-slide-x-transition>
      <Header @show-search-modal="showModal"
              :isMobileMenuVisible="isMobileMenuVisible"
              @show-mobile-menu-open="showMobileMenu"

              :isLogin="isLogin"
              @logout="logOut"
      />
      <div id="consent_blackbar"></div>
      <slot/>
      <div class="top-nav-overlay-mb" v-show="isMobileMenuVisible"></div>
      <Footer/>
      <search :is-visible="isVisible" @show-search-modal="showModal" @getSearchData="getSearchData"/>
    </div>

    <TherapeuticAreaModal/>
    <!-------------------파이프라인 모달창------------------->
    <div class="modal" v-if="isExternalModalVisible">
      <!-- 사이트 이탈 Modal의 내용 -->
      <div class="modal_contents">
        <div class="modal_close close">
          <a @click="showExternalModal">close</a>
        </div>
        <p class="modal_tit">BMS 글로벌 웹사이트로 이동합니다.</p>
        <p class="modal_exp">현재 웹사이트를 벗어나 BMS 글로벌 웹사이트로 이동합니다.
          해당 웹사이트는 한국BMS제약이 아닌 BMS Global 본사에서 운영하는 웹사이트로 해당 웹사이트의 제품
          콘텐츠 중 일부는 국내 허가사항과 다를 수 있습니다. 외부 사이트 링크는 단지 BMSON 웹사이트
          사용자들에게 편의를 제공하기 위한 목적으로 제공합니다.</p>
        <div class="modal_btn">
          <span class="btn_modal" @click="showExternalModal({externalUrl:externalUrl})">계속하기</span>
          <span class="btn_modal" @click="showExternalModal">취소</span>
        </div>
      </div>
    </div>
    <!-------------------파이프라인 모달창------------------->
  </div>
</template>

<script>
import Footer from '@/components/common/Footer.vue';
import Header from '@/components/common/Header.vue';
import '@/assets/css/style.css';
import Search from "@/components/modal/Search";
import MobileHeader from "@/components/common/MobileHeader";
import TherapeuticAreaModal from "@/components/modal/TherapeuticAreaModal";

export default {
  name: "MainLayout",
  components: {
    TherapeuticAreaModal,
    MobileHeader,
    Footer,
    Header,
    Search,
  },
  data() {
    return {
      isVisible: false,
      isMobileMenuVisible: false,
      isExternalModalVisible: false,
      externalUrl: ''
    }
  },
  computed: {
    isLogin() {
      return this.$store.getters.isLogin
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('logout')
    },
    showModal() {
      this.isVisible = !this.isVisible;
      if (this.isVisible) {
        document.documentElement.classList.add("overflow-y-hidden");
      } else {
        document.documentElement.classList.remove("overflow-y-hidden");
      }
    },
    showExternalModal(payload) {
      let {setUrl, externalUrl} = payload
      this.isExternalModalVisible = !this.isExternalModalVisible;
      this.externalUrl = setUrl
      if (externalUrl) {
        window.open(externalUrl, '_blank');
      }
      if (this.isExternalModalVisible) {
        document.documentElement.classList.add("overflow-y-hidden");
      } else {
        document.documentElement.classList.remove("overflow-y-hidden");
      }
    },
    showMobileMenu() {
      this.isMobileMenuVisible = !this.isMobileMenuVisible;
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
      if (this.isMobileMenuVisible) {
        document.documentElement.classList.add("overflow-y-hidden");
      } else {
        document.documentElement.classList.remove("overflow-y-hidden");
      }
    },
    getSearchData(searchKeyword) {
      this.$router.push({
        name: 'searchResultPage',
        params: {
          searchKeyword: searchKeyword
        }
      })
      this.showModal()
    },
    getSearchModalData(searchKeyword) {
      this.$router.push({
        name: 'searchResultPage',
        params: {
          searchKeyword: searchKeyword
        }
      })
      this.showMobileMenu()
    }
  },
};
</script>

<style scoped>
</style>
