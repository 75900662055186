const productRouter = {
    path: '/menu/:productName',
    component: () => import('@/views/Product'),
    meta: {auth: true},
    children: [
        {
            path: '/',
            name: 'Product',
            component: () => import('@/views/Product/Landing'),
            meta: {
                displayName: 'BMSON Product(Landing)',
                auth: true
            },
        },
        {
            path: 'external-link',
            name: 'ProductExternalLink',
            component: () => import('@/views/Product/ExternalLink'),
            meta: {
                displayName: 'BMSON Product > Related Website',
                auth: true
            },
        },
        {
            path: 'insurance',
            name: 'ProductInsurance',
            component: () => import('@/views/Product/Insurance'),
            meta: {
                displayName: 'BMSON Product > Permissions & Insurance',
                auth: true
            },
        },
        {
            path: 'brochure',
            name: 'BrochureList',
            component: () => import('@/views/Product/Brochure'),
            meta: {
                displayName: 'BMSON Product > Brochure',
                auth: true
            },
        },
        {
            path: 'brochure/:brochureNo',
            name: 'BrochureView',
            component: () => import('@/views/Product/Brochure/View'),
            meta: {
                displayName: 'BMSON Product > Brochure Contents',
                auth: true
            },
        },
        {
            path: 'clinical-trial/',
            name: 'ClinicalTrialList',
            component: () => import('@/views/Product/ClinicalTrial/'),
            meta: {
                displayName: 'BMSON Product > Related Journal',
                auth: true
            },
        },
        {
            path: 'clinical-trial/:clinicalTrialNo',
            name: 'ClinicalTrialView',
            component: () => import('@/views/Product/ClinicalTrial/View'),
            meta: {
                displayName: 'BMSON Product > Related Journal Contents',
                auth: true
            },
        },
        {
            path: 'related-disease/',
            name: 'RelatedDiseaseList',
            component: () => import('@/views/Product/RelatedDisease/'),
            meta: {
                displayName: 'BMSON Product > Disease information',
                auth: true
            },
        },
        {
            path: 'related-disease/:relatedDiseaseNo',
            name: 'RelatedDiseaseView',
            component: () => import('@/views/Product/RelatedDisease/View'),
            meta: {
                displayName: 'BMSON Product > Disease information Contents',
                auth: true
            },
        },
        {
            path: 'news',
            name: 'News',
            component: () => import('@/views/Product/News'),
            meta: {
                displayName: 'BMSON Product > Related News',
                auth: true
            },
        },
    ].map(route => {
        return {
            ...route,
            meta: {
                layout: "MainLayout",
                ...route.meta
            }
        }
    })
}

export default productRouter
