<template>
  <div class="modal" v-if="showModal">
    <div class="background" @click="closeModal"></div>
    <!-- 관심 TA Modal의 내용 -->
    <div class="modal_contents">
      <div class="modal_close close">
        <a role="link" @click="closeModal">close</a>
      </div>
      <p class="modal_tit">관심 TA 선택</p>
      <p class="modal_exp">선생님의 주요 관심 질환 영역(Therapeutic Area)을 선택해 주세요.</p>
      <div class="modal_selcet_area">
        <p v-for="(ta,index) in therapeuticAreaList" :key="index">
          <input type="radio" :value="ta.id" :id="`ta_${ta.id}`"
                 v-model="interestTherapeuticArea">
          <label :for="`ta_${ta.id}`" v-text="ta.ename"></label>
        </p>
      </div>
      <div class="modal_btn">
        <span class="btn_modal" @click="saveTherapeuticArea">확인</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "TherapeuticAreaModal",
  data() {
    return {
      showModal: false,
      therapeuticAreaList: [],
      interestTherapeuticArea: ''
    }
  },
  created() {
    this.initTherapeuticAreaList()
  },
  watch: {
    '$route'() {
      this.showModal = typeof this.$route.query['needta'] != 'undefined'
      if (this.showModal) {
        document.documentElement.classList.add("overflow-y-hidden");
      } else {
        document.documentElement.classList.remove("overflow-y-hidden");
      }
    }
  },
  methods: {
    closeModal() {
      this.showModal = !this.showModal;
      if (this.showModal) {
        document.documentElement.classList.add("overflow-y-hidden");
      } else {
        document.documentElement.classList.remove("overflow-y-hidden");
      }
    },
    initTherapeuticAreaList() { //
      this.$store.dispatch('initTherapeuticAreaList').then(therapeuticAreaList => {
        this.therapeuticAreaList = therapeuticAreaList
      })
    },
    saveTherapeuticArea() {
      if (this.interestTherapeuticArea) {
        this.$store.dispatch('dispatchMyInterestTherapeuticArea', {
          id: this.interestTherapeuticArea
        }).then(interestTherapeuticArea => {
          alert('관심질환정보가 설정되었습니다.')
          let query = {...this.$route.query}
          delete query.needta
          this.$router.replace({ ...query && {'query' : query}})
          this.interestTherapeuticArea = interestTherapeuticArea
          this.$router.go(0)
        })
      } else {
        alert('ta를 선택해 주세요')
      }

    }
  },
}
</script>

<style scoped>
.background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

</style>
