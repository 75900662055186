<template>
  <div>
    <slot/>
  </div>
</template>

<script>
import '@/assets/css/style.css';

export default {
  name: "SharedLayout",
};
</script>

<style scoped>
</style>
