<template>
  <footer class="footer bms-relaunch">
    <div class="site-contain">
      <div class="footer-main">
        <div class="footer-navs">
          <div class="footer-nav">
            <h5 class="footer-title">Quick Links</h5>
            <ul class="footer-nav-list">
              <li class="footer-nav-item">
                <router-link :to="'/'"
                   title
                   class="footer-nav-item-link"
                   target="_self"
                   arial-label
                >
                  Home
                </router-link>
              </li>
              <li class="footer-nav-item">
                <router-link :to="'/menu/sprycel'" title class="footer-nav-item-link" target="_self"
                   arial-label>제품</router-link></li>
              <li class="footer-nav-item">
                <router-link :to="'/webinar-symposium/webinar-live'" title class="footer-nav-item-link" target="_self"
                   arial-label>웨비나 & VOD</router-link></li>
              <li class="footer-nav-item">
                <router-link :to="'/request-service'" title
                   class="footer-nav-item-link" target="_self" arial-label>
                  BMS 직원방문 및 연락요청
                </router-link>
              </li>
              <li class="footer-nav-item">
                <router-link :to="'/news/news-and-notice'" title class="footer-nav-item-link" target="_self"
                   arial-label>BMS 소식</router-link></li>
              <li class="footer-nav-item">
                <router-link :to="'/request-report'"
                   title
                   class="footer-nav-item-link"
                   target="_self" arial-label>의학정보요청 및 이상사례보고</router-link></li>
            </ul>
          </div>
          <div class="footer-nav">
            <ul>
              <li class="footer-nav-item">
                <a href="https://www.bms.com/kr/legal-notice.html" title class="footer-nav-item-link" target="_blank"
                   arial-label>이용약관</a></li>
              <li class="footer-nav-item">
                <a href="https://www.bms.com/kr/privacy-policy.html" title class="footer-nav-item-link" target="_blank"
                   arial-label>개인정보보호방침 </a></li>
              <li class="footer-nav-item">
                <router-link :to="'/sitemap'" title class="footer-nav-item-link" target="_self"
                   arial-label>Sitemap</router-link></li>
            </ul>
          </div>
        </div>
        <div class="footer-aside">
          <div class="fooetr-logo">
            <img src="@/assets/images/BTNOn_logo.png" alt="">
          </div>
          <p v-text="footerText">
          </p>
          <p>© 2021 BMS Pharmaceutical Korea Limited. ALL RIGHTS RESERVED.<br>466-KR-2100001 OCT 2021</p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {
      footerText: 'The information provided on this ' +
          'website is intended for Korea-licensed ' +
          'healthcare professionals only and is ' +
          'provided as general information. ' +
          'It is not intended as legal, ' +
          'medical or other professional advice.',
    }
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
