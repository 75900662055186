<template>
  <DefaultLayout :layout="$route.meta['layout']">
    <router-view/>
  </DefaultLayout>
</template>
<script>
import DefaultLayout from "@/components/Layout/DefaultLayout.vue";

export default {
  name: "App",
  components: {
    DefaultLayout,
  },
};
</script>
<style lang="scss">
[role="link"]:hover {
  cursor: pointer;
}

select {
  margin: 2px;
}
</style>

