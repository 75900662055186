import axiosInstance from '@/plugins/proxyHandler'

export default {
    /* 로그인 토큰 수신 */
    async getMember(id) {
        try {
            return await axiosInstance.get(`/member`, {
                params: {
                    ...id && {id: id}
                }
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    /* 로그인 토큰 수신 */
    async checkLogin() {
        return await axiosInstance.get(`/login_check`)
    },
    async myInterestTherapeuticArea() {
        return await axiosInstance.get("api/v1/member/interest-therapeutic-area")
    },
};
