import axiosInstance from '@/plugins/proxyHandler'

export default {
    async getBoardListByType(query, type) {
        try {
            return await axiosInstance.get(`/api/v1/board/${type}`, {
                params: {
                    sort: 'id,desc',
                    size: 12,
                    ...query
                }
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async getBoardsLastUpdatedAt(query, type) {
        try {
            return await axiosInstance.get(`/api/v1/board/${type}/last-updated-at`, {
                params: {
                    ...query
                }
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async getBoardByType(type, id) {
        try {
            return await axiosInstance.get(`/api/v1/board/${type}/${id}`)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async getWebMagazine(type, year, month) {
        try {
            return await axiosInstance.get(`/api/v1/board/${type}/${year}/${month}`)
        } catch (e) {
            console.log(e)
            throw e
        }
    },

    async fetchMagazineList(year, month, type) {
        try {
            return await axiosInstance.get(`/api/v1/board/${type}/${year}/${month}`)
        } catch (e) {
            console.log(e)
            throw e
        }
    },

    async searchBoardByKeyword(query) {
        try {
            return await axiosInstance.get(`/api/v1/board`, {
                params: {
                    sort: "id,desc",
                    ...query
                },
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
};
