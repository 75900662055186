import axios from 'axios';
import store from "@/store";

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, PUT, POST, DELETE, OPTIONS',
        'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization'
    },
});

axiosInstance.interceptors.request.use(
    function (config) {
        const user = store.getters.getMember
        if (user) config.headers['Authorization'] = `Bearer ${user}`;
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)
//
// axiosInstance.interceptors.response.use(response => {
//     return response;
// }, error => {
//     if (error.response.status === 401) {
//         store.dispatch('logout').then()
//     }
//     return error;
// });

export default axiosInstance;
