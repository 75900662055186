// state
const state = {
    //---------------------------------------------------
    // 제품
    productMenu: {
        'inrebic': {
            key: 11,
            head: 'Hematology',
            name: 'INREBIC<sup>®</sup>',
            ingredientName: 'fedratinib',
            logo: null,
            pic: null,
            landing : `https://cdn.bmson.co.kr/static/product/landing/inrebic${process.env.VUE_APP_HTML_SUFFIX}.html`,
            insurance : `https://cdn.bmson.co.kr/static/product/insurance/inrebic${process.env.VUE_APP_HTML_SUFFIX}.html`,
            externalLink : `https://cdn.bmson.co.kr/static/product/external-link/inrebic${process.env.VUE_APP_HTML_SUFFIX}.html`,
            title: 'INREBIC<sup>®</sup> <span>(fedratinib)</span>',
        },
        'onureg': {
            key: 9,
            head: 'Hematology',
            name: 'ONUREG<sup>®</sup>',
            ingredientName: 'azacitidine',
            logo: null,
            pic: null,
            landing : `https://cdn.bmson.co.kr/static/product/landing/onureg${process.env.VUE_APP_HTML_SUFFIX}.html`,
            insurance : `https://cdn.bmson.co.kr/static/product/insurance/onureg${process.env.VUE_APP_HTML_SUFFIX}.html`,
            externalLink : `https://cdn.bmson.co.kr/static/product/external-link/onureg${process.env.VUE_APP_HTML_SUFFIX}.html`,
            title: 'ONUREG<sup>®</sup> <span>(azacitidine)</span>',
        },
        'reblozyl': {
            key: 12,
            head: 'Hematology',
            name: 'REBLOZYL<sup>®</sup>',
            ingredientName: 'luspatercept-aamt',
            logo: null,
            pic: null,
            landing : `https://cdn.bmson.co.kr/static/product/landing/reblozyl${process.env.VUE_APP_HTML_SUFFIX}.html`,
            insurance : `https://cdn.bmson.co.kr/static/product/insurance/reblozyl${process.env.VUE_APP_HTML_SUFFIX}.html`,
            externalLink : `https://cdn.bmson.co.kr/static/product/external-link/reblozyl${process.env.VUE_APP_HTML_SUFFIX}.html`,
            title: 'REBLOZYL<sup>®</sup> <span>(luspatercept-aamt)</span>',
        },
        'revlimid-pomalyst': {
            key: 3,
            head: 'Hematology',
            name: 'REVLIMID<sup>®</sup>/POMALYST<sup>®</sup>',
            ingredientName: 'lenalidomide/pomalidomide',
            logo: require('@/assets/images/product/product_revlimid_logo.png'),
            pic: require('@/assets/images/product/product_revlimid_pic.png'),
            landing : `https://cdn.bmson.co.kr/static/product/landing/revlimid-pomalyst${process.env.VUE_APP_HTML_SUFFIX}.html`,
            insurance : `https://cdn.bmson.co.kr/static/product/insurance/revlimid-pomalyst${process.env.VUE_APP_HTML_SUFFIX}.html`,
            externalLink : `https://cdn.bmson.co.kr/static/product/external-link/revlimid-pomalyst${process.env.VUE_APP_HTML_SUFFIX}.html`,
            title: 'REVLIMID<sup>®</sup> <span>(lenalidomide)</span> / POMALYST<sup>®</sup> <span>(pomalidomide)</span>',
        },
        'sprycel': {
            key: 1,
            head: 'Hematology',
            name: 'SPRYCEL<sup>®</sup>',
            ingredientName: 'dasatinib',
            logo: require('@/assets/images/product/product_sprycel_logo.png'),
            pic: require('@/assets/images/product/product_sprycel_pic.png'),
            landing : `https://cdn.bmson.co.kr/static/product/landing/sprycel${process.env.VUE_APP_HTML_SUFFIX}.html`,
            insurance : `https://cdn.bmson.co.kr/static/product/insurance/sprycel${process.env.VUE_APP_HTML_SUFFIX}.html`,
            externalLink : `https://cdn.bmson.co.kr/static/product/external-link/sprycel${process.env.VUE_APP_HTML_SUFFIX}.html`,
            title: 'SPRYCEL<sup>®</sup> <span>(dasatinib)</span>',
        },
        'thalidomide-celgene': {
            key: 10,
            head: 'Hematology',
            name: 'BMS THALIDOMIDE<sup>™</sup>',
            ingredientName: 'thalidomide',
            logo: null,
            pic: null,
            landing : null,
            insurance : null,
            externalLink : null,
            title: 'BMS THALIDOMIDE<sup>™</sup> <span>(thalidomide)</span>',
        },
        'vidaza': {
            key: 2,
            head: 'Hematology',
            name: 'VIDAZA<sup>®</sup>',
            ingredientName: 'azacitidine',
            logo: require('@/assets/images/product/product_vidaza_logo.png'),
            pic: require('@/assets/images/product/product_vidaza_pic.png'),
            landing : `https://cdn.bmson.co.kr/static/product/landing/vidaza${process.env.VUE_APP_HTML_SUFFIX}.html`,
            insurance : `https://cdn.bmson.co.kr/static/product/insurance/vidaza${process.env.VUE_APP_HTML_SUFFIX}.html`,
            externalLink : `https://cdn.bmson.co.kr/static/product/external-link/vidaza${process.env.VUE_APP_HTML_SUFFIX}.html`,
            title: 'VIDAZA<sup>®</sup> <span>(azacitidine)</span>',
        },
        'opdivo-yervoy': {
            key: 4,
            head: 'Oncology',
            name: 'OPDIVO<sup>®</sup>/YERVOY<sup>®</sup>',
            ingredientName: 'nivolumab/ipilimumab',
            logo: require('@/assets/images/product/product_opdivo_logo.png'),
            pic: require('@/assets/images/product/product_opdivo_pic.png'),
            landing : `https://cdn.bmson.co.kr/static/product/landing/opdivo-yervoy${process.env.VUE_APP_HTML_SUFFIX}.html`,
            insurance : `https://cdn.bmson.co.kr/static/product/insurance/opdivo-yervoy${process.env.VUE_APP_HTML_SUFFIX}.html`,
            externalLink : `https://cdn.bmson.co.kr/static/product/external-link/opdivo-yervoy${process.env.VUE_APP_HTML_SUFFIX}.html`,
            title: 'OPDIVO<sup>®</sup> <span>(nivolumab)</span> / YERVOY<sup>®</sup> <span>(ipilimumab)</span>',
        },
        'abraxane': {
            key: 5,
            head: 'Oncology',
            name: 'ABRAXANE<sup>®</sup>',
            ingredientName: 'paclitaxel protein-bound particles for injectable suspension) (albumin-bound',
            logo: require('@/assets/images/product/product_abraxane_logo.png'),
            pic: require('@/assets/images/product/product_abraxane_pic.png'),
            landing : `https://cdn.bmson.co.kr/static/product/landing/abraxane${process.env.VUE_APP_HTML_SUFFIX}.html`,
            insurance : `https://cdn.bmson.co.kr/static/product/insurance/abraxane${process.env.VUE_APP_HTML_SUFFIX}.html`,
            externalLink : `https://cdn.bmson.co.kr/static/product/external-link/abraxane${process.env.VUE_APP_HTML_SUFFIX}.html`,
            title: 'ABRAXANE<sup>®</sup> <span>(paclitaxel protein-bound particles for injectable suspension) (albumin-bound)</span>',
        },
        'zeposia': {
            key: 13,
            head: 'Immunology',
            name: 'ZEPOSIA<sup>®</sup>',
            ingredientName: 'ozanimod',
            logo: null,
            pic: null,
            landing : `https://cdn.bmson.co.kr/static/product/landing/zeposia${process.env.VUE_APP_HTML_SUFFIX}.html`,
            insurance : `https://cdn.bmson.co.kr/static/product/insurance/zeposia${process.env.VUE_APP_HTML_SUFFIX}.html`,
            externalLink : `https://cdn.bmson.co.kr/static/product/external-link/zeposia${process.env.VUE_APP_HTML_SUFFIX}.html`,
            title: 'ZEPOSIA<sup>®</sup> <span>(ozanimod)</span>',
        },
        'orencia': {
            key: 6,
            head: 'Immunology',
            name: 'ORENCIA<sup>®</sup>',
            ingredientName: 'abatacept',
            logo: require('@/assets/images/product/product_orencia_logo.png'),
            pic: require('@/assets/images/product/product_orencia_pic.png'),
            landing : `https://cdn.bmson.co.kr/static/product/landing/orencia${process.env.VUE_APP_HTML_SUFFIX}.html`,
            insurance : `https://cdn.bmson.co.kr/static/product/insurance/orencia${process.env.VUE_APP_HTML_SUFFIX}.html`,
            externalLink : `https://cdn.bmson.co.kr/static/product/external-link/orencia${process.env.VUE_APP_HTML_SUFFIX}.html`,
            title: 'ORENCIA<sup>®</sup> <span>(abatacept)</span>',
        },
        'baraclude': {
            key: 7,
            head: 'Virology',
            name: 'BARACLUDE<sup>®</sup>',
            ingredientName: 'entecavir',
            logo: require('@/assets/images/product/product_baraclude_logo.png'),
            pic: require('@/assets/images/product/product_baraclude_pic.png'),
            landing : `https://cdn.bmson.co.kr/static/product/landing/baraclude${process.env.VUE_APP_HTML_SUFFIX}.html`,
            insurance : `https://cdn.bmson.co.kr/static/product/insurance/baraclude${process.env.VUE_APP_HTML_SUFFIX}.html`,
            externalLink : `https://cdn.bmson.co.kr/static/product/external-link/baraclude${process.env.VUE_APP_HTML_SUFFIX}.html`,
            title: 'BARACLUDE<sup>®</sup> <span>(entecavir)</span>',
        },
        'eliquis': {
            key: 8,
            head: 'Cardiology',
            name: 'ELIQUIS<sup>®</sup>',
            ingredientName: 'apixaban',
            logo: require('@/assets/images/product/product_eliquis_logo.png'),
            pic: require('@/assets/images/product/product_eliquis_pic.png'),
            landing : `https://cdn.bmson.co.kr/static/product/landing/eliquis${process.env.VUE_APP_HTML_SUFFIX}.html`,
            insurance : `https://cdn.bmson.co.kr/static/product/insurance/eliquis${process.env.VUE_APP_HTML_SUFFIX}.html`,
            externalLink : `https://cdn.bmson.co.kr/static/product/external-link/eliquis${process.env.VUE_APP_HTML_SUFFIX}.html`,
            title: 'ELIQUIS<sup>®</sup> <span>(apixaban)</span>',
        },
        'camzyos': {
            key: 14,
            head: 'Cardiology',
            name: 'CAMZYOS<sup>™</sup>',
            ingredientName: 'mavacamten',
            logo: null,
            pic: null,
            landing : `https://cdn.bmson.co.kr/static/product/landing/camzyos${process.env.VUE_APP_HTML_SUFFIX}.html`,
            insurance : `https://cdn.bmson.co.kr/static/product/insurance/camzyos${process.env.VUE_APP_HTML_SUFFIX}.html`,
            externalLink : `https://cdn.bmson.co.kr/static/product/external-link/camzyos${process.env.VUE_APP_HTML_SUFFIX}.html`,
            title: 'CAMZYOS<sup>™</sup> <span>(mavacamten)</span>',
        },
        'sotyktu': {
            key: 15,
            head: 'Immunology',
            name: 'SOTYKTU<sup>™</sup>',
            ingredientName: 'deucravacitinib',
            logo: null,
            pic: null,
            landing : `https://cdn.bmson.co.kr/static/product/landing/sotyktu${process.env.VUE_APP_HTML_SUFFIX}.html`,
            insurance : `https://cdn.bmson.co.kr/static/product/insurance/sotyktu${process.env.VUE_APP_HTML_SUFFIX}.html`,
            externalLink : `https://cdn.bmson.co.kr/static/product/external-link/sotyktu${process.env.VUE_APP_HTML_SUFFIX}.html`,
            title: 'SOTYKTU<sup>™</sup> <span>(deucravacitinib)</span>',
        },

    },
    therapeuticArea: [
        {
            name: 'Hematology',
            productList: ['sprycel', 'vidaza', 'revlimid-pomalyst']
        },
        {
            name: 'Oncology',
            productList: ['opdivo-yervoy', 'abraxane']
        },
        {
            name: 'Immunology',
            productList: ['zeposia','orencia','sotyktu']
        },
        {
            name: 'Virology',
            productList: ['baraclude']
        },
        {
            name: 'Cardiology',
            productList: ['eliquis','camzyos']
        },
    ],
    //---------------------------------------------------

    //---------------------------------------------------
    //마이페이지 tab-menu 리스트, topNav 정보
    menuList: {
        'PreRegistration': {
            menuName: '웨비나 사전등록',
            name: 'PreRegistration',
        },
        "InterestContent": {
            menuName: '관심 콘텐츠',
            name: 'InterestContent',
        },
        "HistoryActing": {
            menuName: '나의 활동 이력',
            name: 'HistoryActing',
        },
        "InterestTherapeuticArea": {
            menuName: "관심 질환 영역",
            name: 'InterestTherapeuticArea',
        },
        "ModifyInformation": {
            menuName: '내 정보 수정',
            name: 'ModifyInformation',
            href: process.env.VUE_APP_PROFILE_UPDATE_URL,//`https://www.wwhcpreg.com/protected/bmsonkr_profileupdate?redirectUrl=${window.location.origin}/my-page/pre-registration`,
            external: true
        },
    }
    //---------------------------------------------------

}

// mutations
const mutations = {}

// actions
const actions = {}

// getters
const getters = {
    getMyPageMenuList: (state) => state.menuList
}

export default {
    state,
    mutations,
    actions,
    getters
}
