import axiosInstance from '@/plugins/proxyHandler'

export default {
    async myRegisterWebinarLiveList(query) {
        try {
            return await axiosInstance.get("api/v1/webinar/register", {
                params: {
                    sort: 'webinar_id,desc',
                    ...query
                }
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async fetchInterestContentListInit(){
        try {
            return await axiosInstance.get("/api/v1/bookmark", {
                params: {
                    sort: "id,desc"
                }
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async getInterestContentListLastUpdatedAt(){
        try {
            return await axiosInstance.get("/api/v1/bookmark/last-updated-at", {
                params: {
                    sort: "id,desc"
                }
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async fetchInterestContentList(page){
        try {
            return await  axiosInstance.get("/api/v1/bookmark", {
                params: {
                    sort: "id,desc",
                    page : page
                }
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },

    async fetchDonateOfSum() {
        try {
            return await axiosInstance.get("/api/v1/common/point/total")
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async fetchHistoryActingListInit() {
        try {
            return await axiosInstance.get("/api/v1/common/history", {
                params: {
                    sort: 'id,desc',
                }
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async fetchHistoryActingList(page) {
        try {
            return await axiosInstance.get("/api/v1/common/history", {
                params: {
                    sort: 'id,desc',
                    page: page
                }
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async postMyInterestTherapeuticArea(id) {
        try {
            return await axiosInstance.post(`api/v1/member/interest-therapeutic-area/${id}`)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async therapeuticAreaList() {
        try {
            return await axiosInstance.get("api/v1/product/therapeutic-area")
        } catch (e) {
            console.log(e)
            throw e
        }
    },


    async getModalInfo(type, id) {
        let urlType = "";
        if(type==="visit-request"){
            urlType = "/api/v1/vr/"
        }else if(type==="medical-request"){
            urlType = "/api/v1/mr/"
        }else if(type==="abnormal-report"){
            urlType = "/api/v1/ar/"
        }
        try {
            return await axiosInstance.get(urlType+`${id}`)
        } catch (e) {
            console.log(e)
            throw e
        }
    }

}
