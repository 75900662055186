import axiosInstance from '@/plugins/proxyHandler'


export default {
    async getWebinarList(query) {
        try {
            return await axiosInstance.get(`/api/v1/webinar/live`, {
                params: {
                    sort:'startDate,asc',
                    ...query
                }
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async getWebinarCalendarList(query) {
        try {
            return await axiosInstance.get(`/api/v1/webinar/live/calendar`, {
                params: {
                    ...query
                }
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async getWebinarVODList(query) {
        try {
            return await axiosInstance.get(`/api/v1/webinar/vod`, {
                params: {
                    ...query
                },
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async getWebinarVODLastUpdatedAt(query) {
        try {
            return await axiosInstance.get(`/api/v1/webinar/vod/last-updated-at`, {
                params: {
                    ...query
                }
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async getWebinar(id) {
        try {
            return await axiosInstance.get(`/api/v1/webinar/live/${id}`)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async getWebinarNotPoint(id) {
        try {
            return await axiosInstance.get(`/api/v1/webinar/live/${id}/not`)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async getWebinarVOD(id) {
        try {
            return await axiosInstance.get(`/api/v1/webinar/vod/${id}`)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async postWebinarRegister(id) {
        try {
            return await axiosInstance.post(`/api/v1/webinar/live/${id}`)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async postWebinarUserInfo() {
        try {
            return await axiosInstance.get(`/api/v1/webinar/user-info`)
        } catch (e) {
            console.log(e)
            throw e
        }
    },

    async searchWebinarByKeyword(query) {
        try {
            return await axiosInstance.get(`/api/v1/webinar/search`, {
                params: {
                    ...query,
                    sort: 'id,desc'
                }
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async getMoreSearchWebinarList(query, page) {
        try {
            return await axiosInstance.get(`/api/v1/webinar/live`, {
                params: {
                    sort: 'id,desc',
                    page: page,
                    ...query
                }
            })
        } catch (e) {
            console.log(e)
            throw e
        }

    },

    async savePointWebinar({id}) {
        try {
            return await axiosInstance.post(`/api/v1/webinar/point/${id}`)
        } catch (e) {
            console.log(e)
            throw e
        }
    }
};
