<template>
  <div>
    <v-expand-transition>
      <div id="modal2" v-show="isVisible" style="display:block" :class="{'modal-open':isVisible}"
           class="modal fade top-nav-search-modal generic-modal full-screen in" tabindex="-1" role="dialog"
           aria-labelledby="Action Label" aria-hidden="true">
        <div class="overlay-bar">
          <button class="close" type="button" data-dismiss="modal" aria-label="Close"
                  @click="$emit('show-search-modal')">
            <i class="icon icon-close">
              <svg class="icon-svg" viewBox="0 0 50 50">
                <title></title>
                <path
                    d="M30.56,25,47.85,7.71a3.93,3.93,0,0,0-5.56-5.56L25,19.44,7.71,2.15A3.93,3.93,0,0,0,2.15,7.71L19.44,25,2.15,42.29a3.93,3.93,0,1,0,5.56,5.56L25,30.56,42.29,47.85a3.93,3.93,0,0,0,5.56-5.56Z"></path>
              </svg>
            </i>
          </button>
        </div>
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="top-nav-search-column">
              <!-- Search section -->
              <div class="top-nav-search">
                <div class="top-nav-search-box">
                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                       xmlns="http://www.w3.org/2000/svg" class="search-icon">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M12.1203 6.81017C12.1203 9.7429 9.7429 12.1203 6.81017 12.1203C3.87745 12.1203 1.5 9.7429 1.5 6.81017C1.5 3.87745 3.87745 1.5 6.81017 1.5C9.7429 1.5 12.1203 3.87745 12.1203 6.81017ZM11.0662 12.1269C9.90036 13.0614 8.42054 13.6203 6.81017 13.6203C3.04902 13.6203 0 10.5713 0 6.81017C0 3.04902 3.04902 0 6.81017 0C10.5713 0 13.6203 3.04902 13.6203 6.81017C13.6203 8.42057 13.0614 9.90042 12.1269 11.0663L16.8747 15.8141L15.814 16.8748L11.0662 12.1269Z"
                          fill="#595454"></path>
                  </svg>
                  <input aria-label="Site Search" placeholder="Search this site"
                         class="top-nav-search-input"
                         @keypress.enter="$emit('getSearchData', searchKeyword)"
                         v-model="searchKeyword"/>
                  <button type="button" data-url="/search-results.html"
                          class="top-nav-search-button"
                          @click="$emit('getSearchData', searchKeyword)">Search
                    <svg width="5" height="8" viewBox="0 0 5 8" fill="none"
                         xmlns="http://www.w3.org/2000/svg" class="chevron-icon">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                            d="M3.77549 4L0.0826091 0.940012C-0.0123075 0.861362 -0.0276965 0.717906 0.0482368 0.619594L0.460705 0.0855644C0.536638 -0.0127477 0.675139 -0.0286873 0.770056 0.0499624L4.86784 3.44546C4.95137 3.51468 5 3.61948 5 3.73028V4.26972C5 4.38052 4.95137 4.48532 4.86784 4.55454L0.770056 7.95004C0.675139 8.02869 0.536638 8.01275 0.460705 7.91444L0.0482368 7.38041C-0.0276965 7.28209 -0.0123074 7.13864 0.0826092 7.05999L3.77549 4Z"
                            fill="#595454"></path>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-expand-transition>

    <div class="modal-backdrop fade in"></div>
  </div>
</template>

<script>
export default {
  name: "Search",
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  created(){
    this.searchKeyword = ""
  },
  data() {
    return {
      searchKeyword: ""
    }
  },

}
</script>

<style scoped>
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
