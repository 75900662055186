import axiosInstance from '@/plugins/proxyHandler'

export default {
    async fetchSurveyList(query) {
        try {
            return await axiosInstance.get(`/api/v1/survey`, {
                params: {
                    ...query,
                    sort: 'id,desc'
                }
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async fetchSurvey(id) {
        try {
            return await axiosInstance.get(`/api/v1/survey/${id}`)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async submitSurvey(id, data) {
        try {
            return await axiosInstance.post(`/api/v1/survey/${id}`, data)
        } catch (e) {
            console.log(e)
            throw e
        }
    },

    async searchSurveyByKeyword(query) {
        try {
            return await axiosInstance.get(`/api/v1/survey`, {
                params: {
                    sort: 'id,desc',
                    ...query
                }
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
};
