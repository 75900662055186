const mypageRouter = {
    path: '/my-page',
    name: 'MyPage',
    props: true,
    redirect: {name: 'PreRegistration'},
    component: () => import('@/views/MyPage'),
    meta: {auth: true},
    children: [
        {
            path: 'pre-registration',
            name: 'PreRegistration',
            component: () => import('@/views/MyPage/PreRegistration'),
            meta: {
                displayName: 'BMSON My Page > Webinar Registration History',
                auth: true
            },
        },
        {
            path: 'interest-content',
            name: 'InterestContent',
            component: () => import('@/views/MyPage/InterestContent'),
            meta: {
                displayName: 'BMSON My Page > Contents of Interest',
                auth: true
            },
        },
        {
            path: 'history-acting',
            name: 'HistoryActing',
            component: () => import('@/views/MyPage/HistoryActing'),
            meta: {
                displayName: 'BMSON My Page > Website Usage History',
                auth: true
            },
        },
        {
            path: 'interest-therapeutic-area',
            name: 'InterestTherapeuticArea',
            component: () => import('@/views/MyPage/InterestTherapeuticArea'),
            meta: {
                displayName: 'BMSON My Page > Therapeutic Area of Interest',
                auth: true
            },
        },
        {
            path: 'modify-information',
            name: 'ModifyInformation',
            component: () => import('@/views/MyPage/ModifyInformation'),
            meta: {
                displayName: 'BMSON My Page > Profile Update',
                auth: true
            },
        },
    ].map(route => {
        return {
            ...route,
            meta: {
                layout: "MainLayout",
                ...route.meta
            }
        }
    })
}

export default mypageRouter
