import myPageAPI from "@/api/myPageAPI"

const state = {
    webinarList: [],
    historyActingList: [],
    historyModalInfo: [],
    isLast: true,
    page: 0,
    therapeuticAreaList: [],
    interestContentList: [],
    interestContentLastUpdatedAt: null,
    interestContentIsLast: true,
    interestContentPage: 0,
}

const getters = {
    getInterestContentIsLast(state) {
      return state.interestContentIsLast;
    },
    getInterestContentPage(state) {
      return state.interestContentPage;
    },
    getInterestContentList(state) {
        return state.interestContentList.map((content) => {
            if (content.type === "brochure") {
                content['link'] = {
                    name: "BrochureView",
                    params: {
                        productName: content.productName,
                        brochureNo: content.link
                    }
                }
            } else if (content.type === "clinical-trial") {
                content['link'] = {
                    name: "ClinicalTrialView",
                    params: {
                        productName: content.productName,
                        clinicalTrialNo: content.link
                    }
                }
            } else if (content.type === "related-disease") {
                content['link'] = {
                    name: "RelatedDiseaseView",
                    params: {
                        productName: content.productName,
                        relatedDiseaseNo: content.link
                    }
                }
            } else if (content.type === "news-and-notice") {
                content['link'] = {
                    name: "NewsView",
                    params: {
                        newsNo: content.link
                    }
                }
            } else if (content.type === "social-contribute") {
                content['link'] = {
                    name: "SocialContributeView",
                    params: {
                        socialContributeNo: content.link
                    }
                }
            } else if (content.type === "webinar-symposium") {
                content['link'] = {
                    name: "SymposiumVODList",
                    params: {
                        symposiumVodNo: content.link
                    }
                }
            }
            return content
        })
    },
    getInterestContentLastUpdatedAt(state) {
        return state.interestContentLastUpdatedAt;
    }
}

const mutations = {
    setWebinarList(state, {webinarList}) {
        state.webinarList = webinarList
    },
    setTherapeuticAreaList(state, {therapeuticAreaList}) {
        state.therapeuticAreaList = therapeuticAreaList
    },
    // setInterestContentList(state, {interestContentList}){
    //     state.interestContentList = interestContentList
    // },
    // setHistoryActingList(state, {historyActingList}){
    //     state.historyActingList = historyActingList
    // }
    // fetchIsLast(state, {isLast}) {
    //     state.isLast = isLast
    //     return state.isLast
    // },
    // fetchPage(state, {page}) {
    //     state.page = page
    //     return state.page
    // },
    setInterestContentIsLast(state, {interestContentIsLast}){
        state.interestContentIsLast = interestContentIsLast
    },
    setInterestContentPage(state, {interestContentPage}){
        state.interestContentPage = interestContentPage
    },
    setInterestContentList(state, {interestContentList}){
        state.interestContentList = interestContentList
    },
    fetchInterestContentList(state, {interestContentList}){
        state.interestContentList = [...state.interestContentList, ...interestContentList]
    },
    fetchInterestContentLastUpdatedAt(state, {interestContentLastUpdatedAt}){
        state.interestContentLastUpdatedAt = interestContentLastUpdatedAt
    },
    setHistoryActingList(state, {historyActingList}) {
        state.historyActingList = historyActingList
    },
    setHistoryModalInfo(state, {historyModalInfo}) {
        state.historyModalInfo = historyModalInfo
    },
    fetchIsLast(state, {isLast}) {
        state.isLast = isLast
        return state.isLast
    },
    fetchPage(state, {page}) {
        state.page = page
        return state.page
    },
    fetchTotalElements(state, {totalElements}) {
        state.totalElements = totalElements
        return state.totalElements
    }
}

const actions = {
    async initMyPageWebinarList({commit}, {query}) {
        try {
            console.log(query)
            const {data: {value: {content, number, last}}} = await myPageAPI.myRegisterWebinarLiveList(query)
            commit('setWebinarList', {webinarList: content})
            return {
                webinarList: content,
                page: number,
                isLast: last
            }
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async dispatchMyInterestTherapeuticArea({commit}, {id: id}) {
        try {
            const {data: {value}} = await myPageAPI.postMyInterestTherapeuticArea(id)
            commit('setInterestTherapeuticArea', {interestTherapeuticArea: value.id})
            return value.id
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async initTherapeuticAreaList({commit}) {
        try {
            const {data: {value}} = await myPageAPI.therapeuticAreaList()
            commit('setTherapeuticAreaList', {therapeuticAreaList: value})
            return state.therapeuticAreaList
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    //--------------------------------------------웨비나사전등록E--------------------------------------------

    //--------------------------------------------관심콘텐츠S--------------------------------------------
    async initInterestContentList({commit}){
        try{
            const{data : {value:{content, last, number}}} = await myPageAPI.fetchInterestContentListInit()
            const {data: {value: lastUpdatedAt}} = await myPageAPI.getInterestContentListLastUpdatedAt()
            commit('setInterestContentList', {interestContentList: content })
            commit('setInterestContentIsLast', {interestContentIsLast: last})
            commit('setInterestContentPage', {interestContentPage: number})
            commit('fetchInterestContentLastUpdatedAt', {interestContentLastUpdatedAt: lastUpdatedAt});
            return {
                interestContentList : state.interestContentList,
                isLast: state.isLast,
                page: state.page,
            }
        }catch (e) {
            console.log(e)
            throw e
        }
    },

    async fetchInterestContentList({commit}, {page}){
        try{
            const{data : {value:{content, last, number}}} = await myPageAPI.fetchInterestContentListInit(page)
            const {data: {value: lastUpdatedAt}} = await myPageAPI.getInterestContentListLastUpdatedAt()
            commit('fetchInterestContentList', {interestContentList : content })
            commit('setInterestContentIsLast', {interestContentIsLast: last})
            commit('setInterestContentPage', {interestContentPage: number})
            commit('fetchInterestContentLastUpdatedAt', {interestContentLastUpdatedAt: lastUpdatedAt});
            return {
                interestContentList : state.interestContentList,
                isLast : state.isLast,
                page : state.page
            }
        }catch (e) {
            console.log(e)
            throw e
        }
    },
    //--------------------------------------------관심콘텐츠E--------------------------------------------


    //--------------------------------------------나의활동이력S--------------------------------------------
    async fetchDonateOfSum() {
        try {
            return await myPageAPI.fetchDonateOfSum()
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async fetchHistoryActingListInit({commit}) {
        try {
            const {data: {value}} = await myPageAPI.fetchHistoryActingListInit()
            commit('setHistoryActingList', {historyActingList: value.content})
            commit('fetchIsLast', {isLast: value.last})
            commit('fetchPage', {page: value.number})
            commit('fetchTotalElements', {totalElements: value.totalElements})
            console.log(value)
            return {
                historyActingList: state.historyActingList,
                isLast: state.isLast,
                page: state.page,
                totalElements: state.totalElements
            }
        } catch (e) {
            console.log(e)
            throw e
        }
    },

    async fetchHistoryActingList({commit}, {page}) {
        try {
            const {data: {value}} = await myPageAPI.fetchHistoryActingList(page)
            commit('setHistoryActingList', {historyActingList: state.historyActingList.concat(value.content)})
            commit('fetchIsLast', {isLast: value.last})
            commit('fetchPage', {page: value.number})
            // commit('fetchTotalElements', {totalElements: value.totalElements })
            return {
                historyActingList: state.historyActingList,
                isLast: state.isLast,
                page: state.page,
                // totalElements: state.totalElements
            }
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    async getModalInfo({commit}, {type, id}) {
        try {
            const data = await myPageAPI.getModalInfo(type, id)
            commit('setHistoryModalInfo', {historyModalInfo: data})
            return data
        } catch (e) {
            console.log(e)
            throw e
        }
    }
    //--------------------------------------------나의활동이력E--------------------------------------------

    // async initHistoryActingList({commit}){
    //     try{
    //         const {data} = await myPageAPI.initHistoryActingList()
    //         commit('setHistoryActingList', {historyActingList: data })
    //         return {
    //             historyActingList : state.historyActingList
    //         }
    //     }catch (e) {
    //         console.log(e)
    //         throw e
    //     }
    // }

    // async fetchHistoryActingList({commit}, {query}){
    //     try{
    //         const {data} = await myPageAPI.fetchHistoryActingList(query)
    //         commit('setHistoryActingList', {historyActingList: data })
    //         commit('fetchIsLast', {isLast: last})
    //         commit('fetchPage', {page: number})
    //         return {
    //             historyActingList : state.historyActingList,
    //             isLast : state.isLast,
    //             page : state.page
    //         }
    //     }catch (e) {
    //         console.log(e)
    //         throw e
    //     }
    // }
}


export default {
    state,
    mutations,
    actions,
    getters
}
