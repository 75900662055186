<template>
  <component v-if="layout" :is="layout">
    <slot/>
  </component>
</template>

<script>
import SharedLayout from "@/components/Layout/SharedLayout.vue";
import MainLayout from "@/components/Layout/MainLayout.vue";

export default {
  name: "DefaultLayout",
  components: {
    MainLayout,
    SharedLayout
  },
  props: {
    layout: {
      type: String,
    },
  },
  created() {
  },
};
</script>

<style scoped></style>
