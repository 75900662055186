import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
// 작성한 모듈을 가져옵니다.
import menu from '@/store/modules/menu'
import login from '@/store/modules/login'
import board from "@/store/modules/board";
import webinar from "@/store/modules/webinar";
import myPage from "@/store/modules/mypage";
import survey from "@/store/modules/survey";

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        // 키: 값 형태로 저장됩니다.
        menu: menu,
        login: login,
        board: board,
        webinar: webinar,
        myPage: myPage,
        survey: survey
    },
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
            paths: ["login"]
        })
    ],
})

export default store
